import React from "react"
import Layout from "../chunks/layout"
import { Helmet } from "react-helmet"
import Main from "../components/main"

export default function TermsAndConditions({ location }) {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Checkout | Boostik</title>
        <link rel="canonical" href="http://boostik.io/" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Main title="Terms and Conditions" viewName="terms" />
      <div className="p-10" style={{
        maxWidth: "80%",
        margin: "auto",
        textAlign: 'justify'
      }}>
        <h2 style={{ fontSize: "18px", textAlign: "center" }}>Terms and conditions</h2>
        <h3 style={{marginTop: "30px"}}>I.- Ownership.</h3>
        <p>
          <p>In compliance with Law 34/2002 on Information Society Services and Electronic Commerce (LSSICE), we inform that this site is property and is operated by Boostik, a Spanish trading company with registered address at Arc de Sant Marti 83 (Barcelona), Spain.</p>
          <p>E-mail address: info@boostik.io</p>
        </p>
        <h3>II.- Legal Notice and Conditions of use.</h3>
        <p>
          www.boostik.io is a website owned by Boostik. Initially, the use of the website is free, and in some
          cases, such use could be subject to General Conditions and Particular Conditions of use. Therefore, the access
          and use of the services of the website will mean the acceptance of these Conditions.
        </p>
        <h3>III.- Content, legal terms and responsibilities.</h3>
        <p>
          The website includes content prepared by the owner of the same and his collaborators, which are for
          informational purposes only, since on some occasion, these may not reflect the most recent news, so that at
          any time said content may be modified and / or or replaced by new ones, without prior notification, or any
          responsibility. The Contents of the website cannot be considered, in any case, as a substitute for any type of
          advice. There will be no type of commercial or professional relationship, or any other type of relationship
          with the professionals that make up the website by the mere fact of access to it by users. In the case of
          links to different websites, Boostik. and its owner, will not be responsible for its content as they lack
          control over them. When the user accesses the website, he does so at his own risk and expense. Boostik and
          its owner do not guarantee speed, uninterruption, or the absence of viruses on the website. Likewise, the
          owner of the website, the collaborators, their partners, employees and representatives cannot be held
          responsible for any damages arising from the use of this website, nor for any action carried out on the basis
          of the information provided therein. . The absence of viruses or other harmful elements that could cause
          damage or alterations in the computer system, in the electronic documents or in the files of the user of this
          website is not guaranteed. Consequently, it is not liable for damages that such elements may cause to the user
          or to third parties.
        </p>
        <h3>IV.- Intellectual and Industrial Property.</h3>
        <p>
          All rights reserved by Boostik and its owner. The entire contents of this website, such as, but not limited
          to, texts, images, sound, files, brands, logos, color combinations, or any other element, its structure and
          design, the selection and form Presentation of the contents included in it, and the computer programs
          necessary for its operation, access and use, are the property of the owner of the website. The temporary
          storage and reproduction of some or some of the Contents of the website is authorized, with the purpose of
          being used for a personal and non-commercial purpose. The reproduction, permanent storage and dissemination of
          the Contents of the website or any use for public or commercial purposes is strictly prohibited without the
          prior written consent of the owner of the website, and provided that explicit reference is made to Boostik
          and its ownership.
        </p>
        <h3>V.- Services & Money Back Guarantee</h3>
        <p>
          The contracted service will be initiated instantly. Depending on the number of inputs requested, it may take
          up to 5 days to complete
        </p>
        <p>Campaign results applied for each sale cannot be reversed in any way. The contracted product once served is
          irreversible, once delivered there can be no modifications to the service nor can its effects be reversed.</p>
        <p>The service sometimes might deliver later than the planned date stated on the website’s order page. This can
          be completely normal. Contact our support team if your order isn’t delivered in time, they will most likely
          find a suitable solution to accommodate. Kindly note that a late delivery does not mean you will get a
          refund.</p>
        <p>If the data provided by the client is incorrect, the contracted service will be interrupted until the client
          provides the correct data through our support email: info@boostik.io </p>
        <p>
          The user declares that he will have no claim or demand on the grounds of non-conformity of the services on the
          website and is aware that by law, no services will be returned or exchanged for information and / or
          recordable, transcription or replication, i.e. no refund will be made in the event subject. You use this
          service at your own risk. We are not responsible for any of the consequences that may occur because of using
          our services.
        </p>
        <p>Boostik products and services are to be used for promotional purposes only. We do not guarantee that your
          new followers, fans or subscribers will engage with your account in any way beyond the service explicitly
          purchased. You agree and understand that you are bound by the Terms of Service of the social network in which
          you are requesting our marketing services.</p>
        <p>Boostik offers its clients the option to refund their money if their needs are not met during the campaign.
          We offer non-tangible irrevocable goods, do not issue refunds once the order is accomplished and the product
          is sent. If a track / profile / video gets deleted after we have started our work or the promotion has been completed, we will
          not make any refunds. As a customer, you are responsible for understanding this upon purchasing any item at
          our site.</p>

        <h3>
          VI.- Payment
        </h3>
        <p>
          You agree that upon purchasing a product or service from Boostik:
        </p>
        <p>1. You understand what you are purchasing.</p>
        <p>2. You have authorization to use the credit card, debit card, PayPal account or any other payment source used
          to make the purchase.</p>
        <p>3. You will not chargeback, dispute, reverse any payment until after discussing the situation with our
          support team.</p>

        <h3>
          VII. Copyright and Trademarks
        </h3>
        <p>
          Boostik is in no way affiliated with any of the companies or social networks in which we provide marketing
          services for. This includes but is not limited to: Instagram, TikTok, YouTube and SoundCloud. You will in no
          way copy any code or content on Boostik’s website without written consent from a Boostik representative.
        </p>
        <h3>VIII.- Liabilities</h3>
        <p>
          Under no circumstance shall Boostik be responsible for any damages, liabilities, or losses, whether sounding
          in contract, tort, or otherwise, arising out of your use of Boostik or the services. You understand and
          acknowledge that your use of the services is at your own risk.
        </p>
        <h3>IX.- Applicable law and jurisdiction.</h3>
        <p>
          These conditions of use are governed by Spanish legislation, with the Courts and Tribunals of the city of
          Barcelona being competent to know how many questions arise about the interpretation, application and
          compliance with them. Users, by virtue of their acceptance of the general conditions contained in this legal
          notice, expressly renounce any jurisdiction that, by application of the current Civil Procedure Law, may
          correspond to them.
        </p>
      </div>
    </Layout>
  )
}
