import React from "react"
import Menu from "../components/menu"
import { Button } from "antd"
import { navigate } from "gatsby"

const Main = props => {
  const scrollToDiv = id => {
    const el = document.getElementById(id);
    console.log("scrollToDiv", el);
    window.scroll({ top: el.offsetTop, behavior: "smooth" })
  }
  const getTextBtn = (view) => {
      return "Get Started Today"
  }
  return (
    <div className={`main ${props.viewName}`}>
      <div className="video-background md:hidden">
        <video autoPlay loop muted="true" preload tabIndex="-1" playsInline disableRemotePlayback>
          <source src="/video/video-back.mp4" type="video/mp4" />
          <source src="/video/video-back.webm" type="video/webm" />
        </video>
      </div>
      <Menu />
      <div className="flex justify-center items-center pt-10 middle-info"
           style={{ paddingBottom: "40px", zIndex: 9999, position: "relative" }}>
        <div className={`header-section-content color-home`}>
          <div className={`header-section-title color-home`}>
            <h1 style={{opacity: 0, position: 'absolute'}}>Accelerate your growth on on TikTok</h1>
            <p>Accelerate your</p>
            <p>growth on <img style={{ display: "inline" }} src="/img/tiktoklogo.png" /></p>

          </div>
          {props.subTitle && (
            <div className="header-section-subtitle">{props.subTitle}</div>
          )}
          <p className="header-section-description">{props.description}</p>
          <p className="btns-home" style={{ textAlign: "center" }}>
            <Button
              type="primary"
              shape="round"
              style={{ height: "40px" }}
              className={`background-color-header-button-${props.viewName} background-color-home home-button color-${props.viewName}`}
              onClick={() => {
                return document.getElementById("pricing")
                  ? scrollToDiv("pricing")
                  : navigate("/")
              }}
            >
              {getTextBtn(props.viewName)} <img style={{ display: "inline", marginLeft: "3px" }}
                                                src="/img/RocketLaunch.png" />

            </Button>

            {/*<Button*/}
            {/*  type="primary"*/}
            {/*  shape="round"*/}
            {/*  style={{ height: "40px", marginLeft: "10px" }}*/}
            {/*  className={` btn-white home-button btn-2 color-${props.viewName}`}*/}
            {/*  onClick={() => {*/}
            {/*    return document.getElementById("how-it-works")*/}
            {/*      ? scrollToDiv("how-it-works")*/}
            {/*      : navigate("/")*/}
            {/*  }}*/}
            {/*>*/}
            {/*  See How it Works*/}

            {/*</Button>*/}
          </p>
        </div>
      </div>
      <div className="flex justify-center items-center advantages" style={{ paddingBottom: "40px" }}>
        <div className="home-advantage">
          <img style={{ display: "inline", marginRight: "3px" }} src="/img/Timer.png" /> Fastest Delivery
        </div>
        <div className="home-advantage">
          <img style={{ display: "inline", marginRight: "3px" }} src="/img/LockKey.png" /> Security & Privacy
        </div>
        <div className="home-advantage">
          <img style={{ display: "inline", marginRight: "3px" }} src="/img/SealCheck.png" /> Result Guaranteed
        </div>
        <div className="home-advantage">
          <img style={{ display: "inline", marginRight: "3px" }} src="/img/Headset.png" /> Customer Support
        </div>
      </div>
    </div>
  )
}

export default Main
